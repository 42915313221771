import React from 'react'
import cx from 'classnames'
import { useConfig } from 'hooks'

import { Text } from 'components/ui'

import messages from './messages'
import s from './LiquidityPoolNote.module.scss'


type LiquidityPoolNoteProps = {
  className?: string
  liquidityPool: 'curve' | 'uniswap'
}

const LiquidityPoolNote: React.FC<LiquidityPoolNoteProps> = ({ className, liquidityPool }) => {
  const { config } = useConfig()

  const isCurve = liquidityPool === 'curve'
  const link = isCurve
    ? `https://xdai.curve.fi/factory/1`
    : `https://info.uniswap.org/#/pools/${config.addresses.pools.uniswap.stakedTokenRewardToken.toLowerCase()}`

  const message = {
    ...messages.text,
    values: {
      title: isCurve ? 'Curve' : 'Uniswap',
      link: `
        <a class="${s.link}" href="${link}" target="_blank" rel="noreferrer nofollow">
          <i class="fa-solid fa-up-right-from-square"></i>
        </a>
      `,
    },
  }

  return (
    <Text
      className={cx(className, s.text)}
      message={message}
      color="default"
      align="center"
      size={14}
      dataTestId="reinvest-changing-explanation"
      html
    />
  )
}


export default React.memo(LiquidityPoolNote)

export default {
  sell: {
    en: 'You sell:',
    ru: 'Продаёте:',
    fr: 'You sell:',
    es: 'You sell:',
    pt: 'You sell:',
    de: 'You sell:',
    zh: 'You sell:',
  },
  get: {
    en: 'You get:',
    ru: 'Получаете:',
    fr: 'You get:',
    es: 'You get:',
    pt: 'You get:',
    de: 'You get:',
    zh: 'You get:',
  },
}

import React from 'react'
import { useConfig } from 'hooks'
import { parseEther, formatEther } from '@ethersproject/units'

import { Icon } from 'components/ui'
import { Tooltip } from 'components/feedback'

import messages from './messages'
import s from './SwapPremiumIcon.module.scss'


export type SwapPremiumIconProps = {
  amountOut: string
  amount: string
  liquidityPool: 'curve' | 'uniswap' | null
}

const SwapPremiumIcon: React.FC<SwapPremiumIconProps> = (props) => {
  const { amountOut, amount, liquidityPool } = props

  const { config } = useConfig()

  if (!amount || !amountOut || !liquidityPool) {
    return null
  }

  const bnTotal = parseEther(amountOut)
  const bnAmount = parseEther(amount)
  const isCurve = liquidityPool === 'curve'

  const curveLink = 'https://xdai.curve.fi/factory/0'
  const uniswapLink = `https://info.uniswap.org/#/pools/${config.addresses.pools.uniswap.stakedTokenNativeToken}`
  const link = isCurve ? curveLink : uniswapLink

  const tooltipMessage = {
    ...messages.text,
    values: {
      title: isCurve ? 'Curve' : 'Uniswap V3',
      token: isCurve ? 'sGNO' : 'ETH',
      amount: formatEther(bnTotal.sub(bnAmount)),
      link: `
        <a href="${link}" target="_blank" rel="noreferrer nofollow">
          <i class="fa-solid fa-up-right-from-square"></i>
        </a>
      `,
    },
  }

  return (
    <Tooltip className={s.tooltip} content={tooltipMessage}>
      <Icon name="fa-solid fa-angles-up" color="success" size={16} />
    </Tooltip>
  )
}


export default React.memo(SwapPremiumIcon)

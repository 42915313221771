export default {
  title: {
    en: 'Orders',
    ru: 'Заказы:',
    fr: 'Orders:',
    es: 'Orders:',
    pt: 'Orders:',
    de: 'Orders:',
    zh: 'Orders:',
  },
}

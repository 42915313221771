export default {
  title: {
    en: 'Failed to send the transaction',
    ru: 'Не удалось отправить транзакцию',
    fr: 'Impossible d\'envoyer la transaction',
    es: 'La envío de la transacción ha fallado',
    pt: 'Falha ao enviar a transação',
    de: 'Das Senden der Transaktion ist fehlgeschlagen',
    zh: '交易發送失敗',
  },
  reinvestText: {
    en: 'There is an issue with reinvesting your rewards in a single transaction. You must approve your {token} tokens transfer to reinvest.',
    ru: 'Возникла проблема с реинвестированием Ваших вознаграждений за одну транзакцию. Вам необходимо одобрить перевод токенов {token} для реинвестирования.',
    fr: 'Il y a eu un problème pour réinvestir les récompenses dans une seule transaction. Merci d\'approuver les jetons {token} afin de réinvestir',
    es: 'Ha ocurrido un error reinvirtiendo sus ganancias en una única transacción. Debe aprobar la transferencia de sus {token} tokens para reinvertir.',
    pt: 'Há um problema em reinvestir suas recompensas em uma única transação. Você deve aprovar sua transferência de tokens {token} para reinvestir.',
    de: 'Es gibt ein Problem mit dem Reinvestieren Deiner Belohnungen in einer einzelnen Transaktion. Du musst den {token} Token Transfer genehmigen, um zu reinvestieren.',
    zh: '無法靠一個交易將您的獎勵投入質押。您必須允許 {token} 代幣轉移才能將獎勵投入質押。',
  },
  stakeText: {
    en: 'There is an issue with staking transaction. Please confirm your {token} tokens transfer to stake.',
    ru: 'Есть проблема с транзакцией стейкинга. Пожалуйста, подтвердите перевод ваших токенов {token} на стейкинг.',
    fr: 'Il y a un problème avec la transaction de jalonnement. Veuillez confirmer le transfert de vos jetons {token} vers le stake.',
    es: 'Hay un problema con la transacción de replanteo. Confirme la transferencia de tokens de {token} para apostar.',
    pt: 'Há um problema com a transação de staking. Confirme sua transferência de tokens {token} para a aposta.',
    de: 'Es gibt ein Problem mit Staking-Transaktionen. Bitte bestätigen Sie die Übertragung Ihrer {token}-Token zum Stake.',
    zh: '質押交易存在問題。 請確認您的 {token} 代幣轉移到質押。',
  },
  batchStakeText: {
    en: 'There is an issue with staking in a single transaction. Please approve your {token} tokens transfer to stake.',
    ru: 'Есть проблема со стейкингом в одной транзакции. Пожалуйста, подтвердите перевод ваших токенов {token} на стейкинг.',
    fr: 'Il y a un problème avec le staking en une seule transaction. Merci d\'approuver les  transferts de jetons {token} pour staker.',
    es: 'Ha ocurrido un error al intentar hacer staking en una transacción. Favor aprobar la transferencia de sus tokens {token} para hacer stake.',
    pt: 'Há um problema em staking em uma única transação. Aprove sua transferência de tokens {token} para stake.',
    de: 'Es gibt ein Problem mit dem Staking über eine einzelne Transaktion. Bitte genehmige den Transfer Deiner {token} Tokens um zu staken.',
    zh: '無法將質押的代幣包裹在單一交易內。請確認您已經授權允許 {token} 代幣轉移來完成質押。',
  },
}
